/** @jsx jsx */

import {graphql} from 'gatsby'
import {jsx} from 'theme-ui'
import {Layout, SliceZone, SEO} from '../components'
import {Container} from '../elements'
import Navigation from '../components/navigation'

const Post = ({data: {prismicBlogeintrag}, location}) => {
  if (!prismicBlogeintrag) {
    return
  }
  const {data, lang} = prismicBlogeintrag
  return (
    <Layout>
      <Navigation lang={lang} i18nPathname={undefined} />

      <SEO
        data={data}
        node={prismicBlogeintrag}
        seoTitle={data.seo_title || data.cb_title}
        seoDescription={data.seo_description || data.cb_desc}
        seoImage={data.seo_image.url || data.post_image.url}
        pathname={location.pathname}
        article
      />
      <div>
        <div
          sx={{
            backgroundColor: 'primary',
            p: ['7rem 1.5rem 5rem 1.5rem', '', '12rem 1.5rem 8rem 1.5rem'],
          }}
        >
          <div
            sx={{
              maxWidth: (t) => t.layout.article,
              m: '0 auto',
            }}
          >
            <h1
              sx={{
                fontSize: ['1rem', '', '1.5rem'],
                lineHeight: 1.4,
                color: '#fde3ce',
                m: ['0 0 0.4rem 0', '', '0'],
              }}
            >
              {prismicBlogeintrag.first_publication_date} — Kategorie:{' '}
              {prismicBlogeintrag.tags.map((tag, i) =>
                i + 1 < prismicBlogeintrag.tags.length ? `${tag}, ` : tag
              )}
            </h1>
            <h2
              sx={{
                fontSize: ['2rem', '', '2.5rem'],
                lineHeight: 1.25,
                color: (t) => t.colors.gray[1],
                m: 0,
              }}
            >
              {data.cb_title}
            </h2>
            <hr
              sx={{
                backgroundColor: '#fff',
                height: '4px',
                width: ['calc(100vw - 6rem)', '', '12rem'],
                margin: '0.25rem 0 0 0',
                padding: '0',
                border: 0,
              }}
            />
          </div>
        </div>
      </div>
      <Container type="article">
        <SliceZone allSlices={data.body1} />
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query PostBySlug($uid: String!) {
    prismicBlogeintrag(uid: {eq: $uid}) {
      first_publication_date(formatString: "DD.MM.YYYY")
      last_publication_date(formatString: "DD.MM.YYYY")
      uid
      lang
      tags
      data {
        cb_title
        cb_desc
        post_image {
          url
        }

        body1 {
          ... on PrismicBlogeintragBody1Text {
            slice_type
            id
            primary {
              text {
                html
              }
            }
          }
          ... on PrismicBlogeintragBody1Image {
            slice_type
            id
            primary {
              image {
                localFile {
                  childImageSharp {
                    fluid {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
            }
          }
          ... on PrismicBlogeintragBody1Quote {
            slice_type
            id
            primary {
              quote {
                html
                text
              }
            }
          }
        }

        seo_title
        seo_description
        seo_image {
          url
        }
      }
    }
  }
`

export default Post
